<template>
  <div>
    <div v-if="isLoading">
      <question-skeleton-loader />
    </div>
    <div class="p-1">
      <!-- Name -->
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Template Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Enter Template Name Here"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Subject -->
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Subject"
            label-for="subject"
          >
            <b-form-input
              id="subject"
              v-model="subject"
              placeholder="Enter Subject Here"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Name -->
      <b-row>
        <b-col md="12">
          <aom-text-editor
            v-if="shallShowEditor"
            v-model="body"
            :value="'<p>Compose a message...</p>'"
          />
        </b-col>
      </b-row>

      <!-- submit and reset -->
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="onReset()"
          >
            Cancel
          </b-button>
          <action-button
            variant="primary"
            @click="updateTemplate()"
          >
            <b-spinner
              v-if="isUpdating"
              small
            />
            <span v-if="isUpdating"> Updating...</span>
            <span v-else>Submit</span>
          </action-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/views/components/common/ActionButton.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AomTextEditor from '@aom-core/AoMTextEditor.vue';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { commsService } from '@/services';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    AomTextEditor,
    ActionButton
  },
  directives: {
    Ripple,
  },
  props: {
    shallShowEditor: {
      type: Boolean,
    },
    template: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: undefined,
      subject: undefined,
      body: undefined,
      isLoading: false,
      isUpdating: false,
    };
  },
  watch: {
    template: {
      handler() {
        if (this.template) {
          this.name = this.template.name;
          this.subject = this.template.subject;
          this.body = this.template.body;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async updateTemplate() {
      this.isUpdating = true;
      try {
        const payload = {
          id: this.template.id,
          name: this.name,
          subject: this.subject,
          body: this.body,
        };
        const response = await commsService.updateProgramInviteTemplate(
          this.$route.params.id,
          this.template.id,
          payload
        );

        if (response) {
          this.$emit("templateUpdated");
          this.$toast(makeSuccessToast("Template updated successfully."));
          this.$root.$emit("bv::toggle::collapse", "edit-template-right");
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },
    onReset() {
      this.name = undefined;
      this.subject = undefined;
      this.body = undefined;
      this.$root.$emit("bv::toggle::collapse", "edit-template-right");
    },
  }
};
</script>
