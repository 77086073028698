<template>
  <div class="p-1">
    <validation-observer
      ref="observer"
      v-slot="{ invalid, handleSubmit }"
    >
      <!-- Name -->
      <b-row>
        <b-col md="12">
          <validation-provider
            v-slot="validationContext"
            ref="name"
            :rules="{ required: true }"
            vid="name"
            name="name"
          >
            <b-form-group
              label="Template Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Enter Template Name Here"
                :state="getValidationState(validationContext)"
                :aria-invalid="
                  !getValidationState(validationContext)
                    ? 'true'
                    : 'false'
                "
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Subject -->
      <b-row>
        <b-col md="12">
          <validation-provider
            v-slot="validationContext"
            ref="subject"
            :rules="{ required: true }"
            vid="subject"
            name="subject"
          >
            <b-form-group
              label="Subject"
              label-for="subject"
            >
              <b-form-input
                id="subject"
                v-model="subject"
                placeholder="Enter Subject Here"
                :state="getValidationState(validationContext)"
                :aria-invalid="
                  !getValidationState(validationContext)
                    ? 'true'
                    : 'false'
                "
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Body -->
      <b-row>
        <b-col md="12">
          <aom-text-editor
            v-if="shallShowEditor"
            v-model="body"
            :value="'<p>Compose a message...</p>'"
            :type="emailComposeTypes.INVITE_TEMPLATES"
          />
        </b-col>
      </b-row>

      <!-- Submit and reset -->
      <b-row class="mt-2">
        <b-col cols="12">
          <b-button
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="onReset()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="handleSubmit(onSubmit)"
          >
            <b-spinner
              v-if="isUpdating"
              small
            />
            <span v-if="isUpdating"> Updating...</span>
            <span v-else>Submit</span>
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BFormInvalidFeedback
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  makeSuccessToast,
  makeErrorToast,
} from "@/libs/utils";
import AomTextEditor from '@aom-core/AoMTextEditor.vue';
import { commsService } from '@/services';
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";
import {
  getValidationState
} from "@/libs/utils";
import { emailComposeTypes } from '@/models';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    AomTextEditor,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  props: {
    shallShowEditor: {
      type: Boolean,
    }
  },
  data() {
    return {
      name: undefined,
      subject: undefined,
      body: undefined,
      isUpdating: false,
      emailComposeTypes
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isUpdating = true;
        const payload = {
          name: this.name,
          subject: this.subject,
          body: this.body
        };
        const response = await commsService.createProgramInviteTemplate(this.$route.params.id, payload);
        if (response) {
          this.$emit("templateAdded", response.data.id);
          this.$toast(makeSuccessToast("Template created successfully."));
          this.$root.$emit("bv::toggle::collapse", "add-template-right");
        }
      } catch (e) {
        this.$toast(
          makeErrorToast("Something went wrong. Template not saved.")
        );
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    onReset() {
      this.selectedUserRole = [];
      this.selectedCategory = [];
      this.$root.$emit("bv::toggle::collapse", "add-template-right");
    },
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>
