var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"name",attrs:{"rules":{ required: true },"vid":"name","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Template Name","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"Enter Template Name Here","state":_vm.getValidationState(validationContext),"aria-invalid":!_vm.getValidationState(validationContext)
                  ? 'true'
                  : 'false'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"subject",attrs:{"rules":{ required: true },"vid":"subject","name":"subject"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Subject","label-for":"subject"}},[_c('b-form-input',{attrs:{"id":"subject","placeholder":"Enter Subject Here","state":_vm.getValidationState(validationContext),"aria-invalid":!_vm.getValidationState(validationContext)
                  ? 'true'
                  : 'false'},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[(_vm.shallShowEditor)?_c('aom-text-editor',{attrs:{"value":'<p>Compose a message...</p>',"type":_vm.emailComposeTypes.INVITE_TEMPLATES},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}}):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[(_vm.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isUpdating)?_c('span',[_vm._v(" Updating...")]):_c('span',[_vm._v("Submit")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }