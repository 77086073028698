<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list">
      <!-- Create template -->
      <b-card class="shadow-none">
        <b-row>
          <b-col
            sm="12"
            class="d-flex justify-content-end"
          >
            <action-button
              v-b-toggle.add-template-right
              variant="primary"
              style="margin-left: 10px"
            >
              <feather-icon
                size="16"
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="text-nowrap">Create Template</span>
            </action-button>
            <b-sidebar
              id="add-template-right"
              title="Add Template"
              bg-variant="white"
              right
              backdrop
              shadow
              style="min-width: 600px !important"
              width="600px"
              @shown="shallShowEditorForAdd = true"
            >
              <div>
                <add-template
                  :shall-show-editor="shallShowEditorForAdd"
                  @templateAdded="onRefreshItems"
                />
              </div>
            </b-sidebar>
          </b-col>
        </b-row>

        <templates-list
          ref="templatesList"
          @edit="editTemplate"
        />

        <b-sidebar
          id="edit-template-right"
          title="Edit Template"
          bg-variant="white"
          right
          backdrop
          shadow
          style="min-width: 600px !important"
          width="600px"
        >
          <div>
            <edit-template
              :shall-show-editor="shallShowEditorForEdit"
              :template="selectedTemplate"
              @templateUpdated="onRefreshItems"
            />
          </div>
        </b-sidebar>
      </b-card>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSidebar,
  VBToggle
} from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import EmailLeftSidebar from "@/views/apps/champion/communication/EmailLeftSidebar.vue";
import AddTemplate from "@/views/apps/champion/communication/invites/AddTemplate.vue";
import EditTemplate from "@/views/apps/champion/communication/invites/EditTemplate.vue";
import TemplatesList from "@/views/apps/champion/communication/invites/TemplatesList.vue";
import Ripple from "vue-ripple-directive";
import ActionButton from "@/views/components/common/ActionButton.vue";


export default {
  components: {
    ActionButton,
    BCard,
    BRow,
    BCol,
    BButton,
    BSidebar,
    EmailLeftSidebar,
    AddTemplate,
    EditTemplate,
    TemplatesList
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      selectedTemplate: null,
      shallShowEditorForAdd: false,
      shallShowEditorForEdit: false
    };
  },
  methods: {
    onRefreshItems() {
      this.$refs.templatesList.loadItems();
      this.shallShowEditorForAdd = false;
      this.shallShowEditorForEdit = false;
    },

    editTemplate(template) {
      this.selectedTemplate = template;
      this.shallShowEditorForEdit = true;
    },
  },
  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false);

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      formatDateToMonthShort,
      shallShowEmailComposeModal,
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
  $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #add-template-right___title__ {
        font-size: .8em;
        flex: 2;
        text-align: left;
      }
      #edit-template-right___title__ {
        font-size: .8em;
        flex: 2;
        text-align: left;
      }
    }
</style>
